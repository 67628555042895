<template>
  <div class="brand">
    <div style="height: 90px"></div>
    <div class="top_title">
      <img class="" src="~@/assets/images/trademarkData/top_background.png" alt="">
      <h1 class="top_title_content">蓝灯鱼 <img src="~@/assets/images/trademarkData/drop.png" alt="">商标大数据</h1>
    </div>
    <div style="height: 146px">

    </div>

    <div v-if="updateDate.length > 0" class="updateDate">
      更新至：{{updateDate[0] + '年' + updateDate[1] + '月' + updateDate[2] + '日'}}
    </div>

    <div class="brand_content">
      <div style="display: flex;justify-content: space-between;position: relative;z-index: 2">
        <ul class="brand_content_total" style="display: flex;width: 60%">
          <li>
            <p>商标总量</p>
            <p>{{totalCount}}</p>
          </li>
          <li>
            <p>有效注册量</p>
            <p>{{effictiveCount}}</p>
          </li>
          <li>
            <p>{{value3}}年申请量</p>
            <p>{{appCount}}</p>
          </li>
        </ul>


        <div style="display: flex">
          <div class="seletyear">

            <ul class="nav">
              <li @mouseenter="mouseenter(0)" @mouseleave="mouseleave(0)" class="drop-down">
                <div style="background: #12344b;" class="drop-down_content">
                  <div class="drop-down_content_title">
                    <img src="~@/assets/images/trademarkData/select_1.png" alt="">
                    <img src="~@/assets/images/trademarkData/select_2.png" alt="">
                    <img src="~@/assets/images/trademarkData/select_3.png" alt="">
                    <img src="~@/assets/images/trademarkData/select_4.png" alt="">
                    <img src="~@/assets/images/trademarkData/calendar.png" alt="">
                    <span style="line-height: 0;font-size: 18px">{{value3}}&nbsp;年</span>
                    <img class="sanjiao" style="margin-left: 5px;margin-bottom: 2px"
                         src="~@/assets/images/trademarkData/calendarTriangle.png" alt="">
                  </div>

                </div>
                <ul class="drop-down-contents">
                  <li @click="selectYear(item,0)" v-for="(item,index) in seletYear"><span >{{item.label}}</span></li>
                </ul>
              </li>

              <li @mouseenter="mouseenter(1)" @mouseleave="mouseleave(1)" style="margin-left: 47px" class="drop-down">
                <div class="drop-down_content">
                  <div class="drop-down_content_title">
                    <img src="~@/assets/images/trademarkData/select_1.png" alt="">
                    <img src="~@/assets/images/trademarkData/select_2.png" alt="">
                    <img src="~@/assets/images/trademarkData/select_3.png" alt="">
                    <img src="~@/assets/images/trademarkData/select_4.png" alt="">
                    <span style="line-height: 0" :style="{fontSize: value.length == 11 ? '13px' : value.length == 8 ? '18px' : '18px'}">{{value}}</span>
                    <img style="margin-bottom: 2px" class="sanjiao"
                         src="~@/assets/images/trademarkData/calendarTriangle.png" alt="">
                  </div>
                </div>
                <ul class="drop-down-contents">
                  <li  @click="ChangeCountry(item,1)" v-for="(item,index) in allCountry">{{item}}</li>
                </ul>
              </li>
            </ul>
          </div>

          <!--          <div>-->
          <!--            <el-select @change="ChangeCountry" v-model="value" placeholder="请选择">-->
          <!--              <el-option-->
          <!--                v-for="item in allCoutry"-->
          <!--                :key="item.value"-->
          <!--                :label="item.city"-->
          <!--                :value="item.city"-->
          <!--              >-->
          <!--              </el-option>-->
          <!--            </el-select>-->
          <!--          </div>-->

        </div>
      </div>

      <div class="switch">
        <ul>
          <li :class="{active:isGraphical}" style="cursor: pointer;list-style: none" @click="graphical(true)"><img
            :src="isGraphical?require('../../assets/images/trademarkData/table.png'):require('../../assets/images/trademarkData/table_default.png')"
            alt=""></li>

          <li :class="{active:!isGraphical}" style="cursor: pointer;list-style: none" @click="graphical(false)"><img
            :src="isGraphical?require('../../assets/images/trademarkData/graphical_default.png'):require('../../assets/images/trademarkData/graphical.png')"
            alt=""></li>
        </ul>
      </div>


      <div style="display: flex;justify-content: space-between;margin-bottom: 115px">
        <div class="MapCom">
          <MapCom :isMultiple="isMultiple"  v-if="isGraphical" @changeCountry="changeCountry" :selectedCountry="recountry"
                  id="dateupMap" width="100%" height="500px"></MapCom>
          <div v-else style="width: 100%">
            <div style="float: right;margin-bottom: 5px;color: rgb(81, 220, 253);font-size: 14px">申请量/注册量</div>
            <el-table
              :header-cell-style="{ background: '#121933', color: '#51dcfd', fontSize: '15px',borderBottom: '1px solid #203866' }"
              :cell-style="{ background: '#121933', color: '#51dcfd', fontSize: '15px',borderBottom: '1px solid #203866' }"
              ref="tableList"
              class="maptable"
              width="100%"
              height="400px"
              :data="tableDatas"
              border
              style="width: 100%">
              <el-table-column
                prop="city"
                fixed
                align="center"
                label="数据库"
                width="120">
              </el-table-column>
              <el-table-column
                v-for="item in seletAllyear"
                align="center"
                :key="item"
                :prop="item"
                :label="item"
                width="160">
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="echartsPie">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <img src="~@/assets/images/trademarkData/Cpie1.png" alt="">
          <img src="~@/assets/images/trademarkData/Cpie2.png" alt="">
          <img src="~@/assets/images/trademarkData/Cpie4.png" alt="">
          <img src="~@/assets/images/trademarkData/Cpie3.png" alt="">
          <div class="chartsPie1" style="width: 50%;height: 100%;float: left">
            <div>法律状态分布</div>
            <pie v-if="pieDatas.length != 0" style="width: 100%;height: 100%" :dataT="pieDatas" id="pie2"></pie>
            <div class="available" v-else>
              <img  src="~@/assets/images/trademarkData/available.png" alt="">
              <span>暂未开启</span>
            </div>

            <!--          <transverse :transverseData="transverseData" v-else style="width: 100%;height: 100%" id="transverse1"></transverse>-->
          </div>
          <div class="chartsPie2" style="width: 50%;height: 100%;float: right">
            <div>服务类别分布</div>
            <pie v-if="pieData.length != 0 && appCount != 0"  style="width: 100%;height: 100%" :dataT="pieData" id="pie1"></pie>
            <div class="available" v-else>
              <img  src="~@/assets/images/trademarkData/available.png" alt="">
              <span>{{pieData.length == 0 ? '暂未开启' : '暂无数据'}}</span>
            </div>
            <!--          <transverse :transverseData="transverseData" v-else style="width: 100%;height: 100%" id="transverse2"></transverse>-->
          </div>
        </div>

      </div>
      <div class="switchs">
        <ul>
          <li :class="{active:trademarkFlag}" style="cursor: pointer;list-style: none" @click="trademarkGraphical(true)">
            <img
              :src="trademarkFlag?require('../../assets/images/trademarkData/table.png'):require('../../assets/images/trademarkData/table_default.png')"
              alt=""></li>

          <li :class="{active:!trademarkFlag}" style="cursor: pointer;list-style: none"
              @click="trademarkGraphical(false)"><img
            :src="trademarkFlag?require('../../assets/images/trademarkData/graphical_default.png'):require('../../assets/images/trademarkData/graphical.png')"
            alt=""></li>
        </ul>
      </div>

      <div class="trademarkTrendsTitle">

      </div>
      <div class="trademarkTrends">

        <div class="trademarkTrends">
          <div class="trademarkTr" v-if="trademarkFlag">
            <div class="patentData">商标趋势</div>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <img src="~@/assets/images/trademarkData/Cpie1.png" alt="">
            <img src="~@/assets/images/trademarkData/Cpie2.png" alt="">
            <img src="~@/assets/images/trademarkData/Cpie4.png" alt="">
            <img src="~@/assets/images/trademarkData/Cpie3.png" alt="">
            <bar :trendsData="trendsData" id="pie3" width="100%" height="500px"></bar>
          </div>

          <div  v-else>
            <div class="patentDatas">商标趋势</div>
            <el-table
              :header-cell-style="{ background: '#121933', color: '#51dcfd', fontSize: '15px',borderBottom: '1px solid #203866' }"
              :cell-style="{ background: '#121933', color: '#51dcfd', fontSize: '15px',borderBottom: '1px solid #203866' }"
              ref="tableLists"
              class="maptable"
              width="100%"
              height="220px"
              :data="trendsTableData"
              border
              style="width: 100%">
              <el-table-column
                prop="name"
                fixed
                align="center"
                :label="value"
                width="130">
              </el-table-column>
              <el-table-column
                v-for="item in seletAllyears"
                align="center"
                :key="item"
                :prop="item"
                :label="item"
                width="160">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>


  </div>

</template>

<script>
  import MapCom from '@/components/chart/MapCom'
  import pie from '@/components/chart/pie'
  import bar from '@/components/chart/bar'
  import transverse from '@/components/chart/transverseBar'
  import {
    countryStatisicForLantern,
    goodsServiceForLantern,
    appAndRegForLantern,
    countryStatisicAllForLantern
  } from "@/request/brand";
  // 亚洲
  const cityOptions = [
    {
      city:'中国大陆',
      code:'CN',
      ingSrc:'/images/national/zgdl.jpg'
    },
    {
      city:'中国香港',
      code:'HK',
      ingSrc:'/images/national/zgxg.jpg'
    },
    {
      city:'印度尼西亚',
      code:'ID',
      ingSrc:'/images/national/ydnxy.jpg'
    },
    {
      city:'印度',
      code:'IN',
      ingSrc:'/images/national/yd.jpg'
    },
    {
      city:'日本',
      code:'JP',
      ingSrc:'/images/national/rb.jpg'
    },
    {
      city:'土耳其',
      code:'TR',
      ingSrc:'/images/national/trq.jpg'
    },
    {
      city:'韩国',
      code:'KR',
      ingSrc:'/images/national/hg.jpg'
    },
    {
      city:'中国澳门',
      code:'MO',
      ingSrc:'/images/national/am.jpg'
    },
    {
      city:'马来西亚',
      code:'MY',
      ingSrc:'/images/national/mlxy.jpg'
    },
    {
      city:'菲律宾',
      code:'PH',
      ingSrc:'/images/national/flb.jpg'
    },
    {
      city:'新加坡',
      code:'SG',
      ingSrc:'/images/national/xjp.jpg'
    },
    {
      city:'中国台湾',
      code:'TW',
      ingSrc:'/images/national/zgtw.png'
    },
    {
      city:'泰国',
      code:'TH',
      ingSrc:'/images/national/tgs.jpg'
    },
    {
      city:'阿拉伯联合酋长国',
      code:'AE',
      ingSrc:'/images/national/alq.jpg'
    }
  ]
  // 欧洲
  const city = [
    {
      city:'奥地利',
      code:'AT',
      ingSrc:'/images/national/adl.JPG'
    },
    {
      city:'瑞士',
      code:'CH',
      ingSrc:'/images/national/rs.jpg'
    },
    {
      city:'德国',
      code:'DE',
      ingSrc:'/images/national/dg.jpg'
    },
    {
      city:'丹麦',
      code:'DK',
      ingSrc:'/images/national/dm.jpg'
    },
    {
      city:'挪威',
      code: 'NO',
      ingSrc:'/images/national/nw.jpg'
    },
    {
      city:'西班牙',
      code:'ES',
      ingSrc:'/images/national/xby.jpg'
    },
    {
      city:'欧盟',
      code:'EU',
      ingSrc:'/images/national/om.jpg'
    },
    {
      city:'芬兰',
      code:'FI',
      ingSrc:'/images/national/fl.jpg'
    },
    {
      city:'法国',
      code:'FR',
      ingSrc:'/images/national/fg.jpg'
    },
    {
      city:'英国',
      code:'GB',
      ingSrc:'/images/national/yg.jpg'
    },
    {
      city:'葡萄牙',
      code:'PT',
      ingSrc:'/images/national/pty.jpg'
    },
    {
      city:'希腊',
      code:'GR',
      ingSrc:'/images/national/xl.jpg'
    },
    {
      city:'匈牙利',
      code:'HU',
      ingSrc:'/images/national/xyl.jpg'
    },
    {
      city:'爱尔兰',
      code:'IE',
      ingSrc:'/images/national/ael.jpg'
    },
    {
      city:'冰岛',
      code:'IS',
      ingSrc:'/images/national/bd.jpg'
    },
    {
      city:'意大利',
      code:'IT',
      ingSrc:'/images/national/ydl.jpg'
    },
    {
      city:'瑞典',
      code:'SE',
      ingSrc:'/images/national/rd.jpg'
    },
    {
      city:'俄罗斯',
      code:'RU',
      ingSrc:'/images/national/elss.jpg'
    },
    {
      city:'比荷卢经济联盟',
      code:'BX',
      ingSrc:'/images/national/bhl.png'
    }
  ]
  // 北美洲
  const cityOptionsd = [
    {
      city:'加拿大',
      code:'CA',
      ingSrc:'/images/national/jnd.jpg'
    },
    {
      city:'墨西哥',
      code:'MX',
      ingSrc:'/images/national/mxg.jpg'
    },
    {
      city:'美国',
      code:'US',
      ingSrc:'/images/national/mg.jpg'
    },
  ]
  // 南美洲
  const cityOptionsds = [
    {
      city:'阿根廷',
      code:'AR',
      ingSrc:'/images/national/agt.jpg'
    },
    {
      city:'巴西',
      code:'BR',
      ingSrc:'/images/national/bx.jpg'
    },
    {
      city:'智利',
      code:'CL',
      ingSrc:'/images/national/zl.jpg'
    },
  ]
  // 非洲
  const cityOpts = [
    {
      city:'南非',
      code:'ZA',
      ingSrc:'/images/national/nf.jpg'
    }
  ]
  // 大洋洲
  const cityds = [
    {
      city:'澳大利亚',
      code:'AU',
      ingSrc:'/images/national/adly.jpg'
    },
    {
      city:'新西兰',
      code:'NZ',
      ingSrc:'/images/national/xxl.jpg'
    }
  ]
  //知识产权组织
  const propertyOrganization = [
    {
      city:'世界知识产权组织国际局',
      code:'WO',
      ingSrc:'/images/national/zscq.jpg'
    }
  ]

  const dataType = [
    {
      type: '01',
      name: '工业用化学品',
      title: '用于工业、科学、摄影、农业、园艺和林业的化学品;未加工人造合成树脂,未加工塑料物质;肥料;灭火用合成物;淬火和焊接用制剂;保存食品用化学品;鞣料;工业用粘合剂'
    },
    {
      type: '02',
      name: '颜料油漆树脂',
      title: '颜料,清漆,漆;防锈剂和木材防腐剂;着色剂;媒染剂;未加工的天然树脂;绘画、装饰、印刷和艺术用金属箔及金属粉'
    },
    {
      type: '03',
      name: '清洁化妆用品',
      title: '洗衣用漂白剂及其他物料;清洁、擦亮、去渍及研磨用制剂;不含药物的肥皂;香料,香精油,不含药物的化妆品,不含药物的润发乳;不含药物的牙膏'
    },
    {
      type: '04',
      name: '油脂燃料能源',
      title: '工业用油和油脂;润滑剂;吸收、润湿和粘结灰尘用合成物;燃料（包括马达用燃料）和照明材料;照明用蜡烛和灯芯'
    },
    {
      type: '05',
      name: '医药卫生用品',
      title: '药品,医用和兽医用制剂;医用卫生制剂;医用或兽医用营养食物和物质,婴儿食品;人用和动物用膳食补充剂;膏药,绷敷材料;填塞牙孔用料,牙科用蜡;消毒剂;消灭有害动物制剂;杀真菌剂,除莠剂'
    },
    {
      type: '06',
      name: '金属五金器具',
      title: '普通金属及其合金,金属矿石;金属建筑材料;可移动金属建筑物;普通金属制非电气用缆线;金属小五金具;存储和运输用金属容器;保险箱'
    },
    {
      type: '07',
      name: '机器机械设备',
      title: '机器和机床;马达和引擎（陆地车辆用的除外）机器联结器和传动机件（陆地车辆用的除外）非手动农业器具;孵化器;自动售货机'
    },
    {
      type: '08',
      name: '餐具手工器具',
      title: '手工具和器具（手动的）刀、叉和勺餐具;随身武器;剃刀'
    },
    {
      type: '09',
      name: '科学电子设备',
      title: '科学、航海、测量、摄影、电影、光学、衡具、量具、信号、检验（监督）、救护（营救）和教学用装置及仪器;处理、开关、转换、积累、调节或控制电的装置和仪器;录制、传送、重放声音或影像的装置;磁性数据载体,录音盘;光盘,DVD 盘和其他数字存储媒介;投币启动装置的机械结构;收银机,计算机器,数据处理装置,计算机;计算机软件;灭火设备'
    },
    {
      type: '10',
      name: '医疗护理器械',
      title: '外科、医疗、牙科和兽医用仪器及器械;假肢,假眼和假牙;矫形用物品;缝合用材料;残疾人专用治疗装置;按摩器械;婴儿护理用器械、器具及用品;性生活用器械、器具及用品'
    },
    {
      type: '11',
      name: '照明空调卫浴',
      title: '照明、加热、蒸汽发生、烹饪、冷藏、干燥、通风、供水以及卫生用装置'
    },
    {
      type: '12',
      name: '交通运输工具',
      title: '运载工具;陆、空、海用运载装置'
    },
    {
      type: '13',
      name: '军火烟火爆竹',
      title: '火器;军火及弹药;爆炸物;烟火'
    },
    {
      type: '14',
      name: '珠宝首饰钟表',
      title: '贵重金属及其合金;首饰,宝石和半宝石;钟表和计时仪器'
    },
    {
      type: '15',
      name: '乐器及其配件',
      title: '乐器'
    },
    {
      type: '16',
      name: '办公教学用品',
      title: '纸和纸板;印刷品;书籍装订材料;照片;文具和办公用品（家具除外）文具用或家庭用粘合剂;艺术家用或绘画用材料;画笔;教育或教学用品;包装和打包用塑料纸、塑料膜和塑料袋;印刷铅字,印版'
    },
    {
      type: '17',
      name: '塑料橡胶制品',
      title: '未加工和半加工的橡胶、古塔胶、树胶、石棉、云母及这些材料的代用品;生产用成型塑料和树脂制品;包装、填充和绝缘用材料;非金属软管和非金属柔性管'
    },
    {
      type: '18',
      name: '箱包皮具皮革',
      title: '皮革和人造皮革;毛皮;行李箱和背包;雨伞和阳伞;手杖;鞭、马具和鞍具;动物用项圈、皮带和衣服'
    },
    {
      type: '19',
      name: '非金属的建材',
      title: '非金属的建筑材料;建筑用非金属刚性管;柏油,沥青;可移动非金属建筑物;非金属碑'
    },
    {
      type: '20',
      name: '家具容器骨石',
      title: '家具,镜子,相框;存储或运输用非金属容器;未加工或半加工的骨、角、鲸骨或珍珠母;贝壳;海泡石;黄琥珀'
    },
    {
      type: '21',
      name: '家居厨房器具',
      title: '家用或厨房用器具和容器;梳子和海绵;刷子（画笔除外）制刷材料;清洁用具;未加工或半加工玻璃（建筑用玻璃除外）玻璃器皿、瓷器和陶器'
    },
    {
      type: '22',
      name: '绳网帐篷帆布',
      title: '缆和绳;网;帐篷和防水遮布;纺织品或合成纤维材料制遮篷;帆;运输和贮存散装物用麻袋;衬垫和填充材料（纸或纸板、橡胶、塑料制除外）纺织用纤维原料及其替代品'
    },
    {
      type: '23',
      name: '纺织用纱和线',
      title: '纺织用纱和线'
    },
    {
      type: '24',
      name: '日常用纺织品',
      title: '织物及其替代品;家庭日用纺织品;纺织品制或塑料制帘'
    },
    {
      type: '25',
      name: '服装服饰鞋帽',
      title: '服装,鞋,帽'
    },
    {
      type: '26',
      name: '花边配饰假发',
      title: '花边和刺绣品,饰带和编带;纽扣,领钩扣,饰针和缝针;假花;发饰;假发'
    },
    {
      type: '27',
      name: '地毯席类墙纸',
      title: '地毯,地席,席类,油毡及其他铺地板材料;非纺织品制墙帷'
    },
    {
      type: '28',
      name: '游戏运动器具',
      title: '游戏器具和玩具;视频游戏装置;体育和运动用品;圣诞树用装饰品'
    },
    {
      type: '29',
      name: '蔬果肉蛋奶油',
      title: '肉,鱼,家禽和野味;肉汁;腌渍、冷冻、干制及煮熟的水果和蔬菜;果冻,果酱,蜜饯;蛋;奶和奶制品;食用油和油脂'
    },
    {
      type: '30',
      name: '咖啡面点调料',
      title: '咖啡,茶,可可和咖啡代用品;米;食用淀粉和西米;面粉和谷类制品;面包、糕点和甜食;食用冰;糖,蜂蜜,糖浆;鲜酵母,发酵粉;食盐;芥末;醋,沙司（调味品）辛香料;冰'
    },
    {
      type: '31',
      name: '水果蔬菜花卉',
      title: '未加工的农业、水产养殖业、园艺、林业产品;未加工的谷物和种子;新鲜水果和蔬菜,新鲜芳香草本植物;草木和花卉;种植用球茎、幼苗和种子;活动物;动物的饮食;麦芽'
    },
    {
      type: '32',
      name: '啤酒饮料糖浆',
      title: '啤酒;矿泉水和汽水以及其他不含酒精的饮料;水果饮料及果汁;糖浆及其他制饮料用的制剂'
    },
    {
      type: '33',
      name: '含酒精的饮料',
      title: '含酒精的饮料（啤酒除外）'
    },
    {
      type: '34',
      name: '烟草烟具火柴',
      title: '烟草;烟具;火柴'
    },
    {
      type: '35',
      name: '广告办公销售',
      title: '广告;商业经营;商业管理;办公事务'
    },
    {
      type: '36',
      name: '保险金融服务',
      title: '保险;金融事务;货币事务;不动产事务'
    },
    {
      type: '37',
      name: '建筑修理安装',
      title: '房屋建筑;修理;安装服务'
    },
    {
      type: '38',
      name: '电信通信服务',
      title: '电信'
    },
    {
      type: '39',
      name: '运输仓储服务',
      title: '运输;商品包装和贮藏;旅行安排'
    },
    {
      type: '40',
      name: '材料处理服务',
      title: '材料处理'
    },
    {
      type: '41',
      name: '教育娱乐文体',
      title: '教育;提供培训;娱乐;文体活动'
    },
    {
      type: '42',
      name: '设计咨询开发',
      title: '科学技术服务和与之相关的研究与设计服务;工业分析与研究;计算机硬件与软件的设计与开发'
    },
    {
      type: '43',
      name: '饮食住宿看护',
      title: '提供食物和饮料服务;临时住宿'
    },
    {
      type: '44',
      name: '医疗美容园林',
      title: '医疗服务;兽医服务;人或动物的卫生和美容服务;农业、园艺和林业服务'
    },
    {
      type: '45',
      name: '法律安全服务',
      title: '法律服务;为有形财产和个人提供实体保护的安全服务;由他人提供的为满足个人需要的私人和社会服务'
    }
  ]
  export default {
    name: "index",
    data() {
      return {
        //商标查询flag
        trademarkFlag: true,
        //商标趋势表格数据
        trendsTableData: [],
        //商标趋势柱状图数据
        trendsData: {},
        //商标趋势全部年份
        seletAllyears: [],
        //地图表格数据
        tableDatas: [],
        //返回的全部年份
        seletAllyear: [],
        //获取选择年份数据
        seletYear: [],
        //是否支持地图多选
        isMultiple: false,
        //服务类别条形柱状图
        transverseData: {},
        tableData: [],
        //选中国家的code
        countryCode: 'CN',
        //显示图形
        isGraphical: true,
        //选择国家
        country: ['中国大陆'],
        //更新日期
        updateDate: [],
        //申请量
        appCount: '',
        //有效注册量
        effictiveCount: '',
        //商标总量
        totalCount: "",
        value: '中国大陆',
        value3: null,
        pieData: [],
        pieDatas: [],
        //下拉所有国家
        allCountry:[]
      }
    },
    components: {
      MapCom,
      pie,
      bar,
      transverse
    },
    mounted() {
      this.getYear()
      this.getCountryData()
      this.getGoodsServiceForLantern()
      this.getAppAndRegForLantern()
      this.countryStatisicAllForLantern()
      this.allCountry = this.pinyinSorting(this.allCoutry.map(item => item.city))
    },
    computed: {
      //所有国家
      allCoutry() {
        return cityOptions.concat(city).concat(cityOptionsd).concat(cityOptionsds).concat(cityOpts).concat(cityds).concat(propertyOrganization)
      },
      recountry() {
        return JSON.parse(JSON.stringify(this.country))
      },
      //获取当前年份
      getFullyear() {
        var date = new Date();
        return date.getFullYear(); //获取完整的年份(4位)
      }
    },
    watch:{
      isGraphical(newValue,oldValue){
        if(!newValue){
          this.$nextTick(() => {
            this.$refs.tableList.bodyWrapper.scrollLeft =this.$refs.tableList.bodyWrapper.scrollWidth;
          })
        }
      },
      trademarkFlag(newValue,oldValue){
        if(!newValue){
          this.$nextTick(() => {
            this.$refs.tableLists.bodyWrapper.scrollLeft =this.$refs.tableLists.bodyWrapper.scrollWidth;
            this.$refs.tableLists.doLayout();
          })
        }
      }
    },
    methods: {
      //获取年份
      getYear() {
        var myDate = new Date();
        var tYear = myDate.getFullYear()
        var tYearL = myDate.getFullYear()
        this.value3 = tYear
        while (tYear > 2009) {
          this.seletYear.push(
            {
              value: tYear--,
              label: tYearL--
            }
          )
        }
      },
      //选择框的鼠标移入移除
      mouseenter(item) {
        document.getElementsByClassName('drop-down-contents')[item].style.display = 'block'
      },

      mouseleave(item) {
        document.getElementsByClassName('drop-down-contents')[item].style.display = 'none'
      },

      //国家按拼音排序(中国大陆排第一)
      pinyinSorting(array){
        var resultArray = array.sort(
          function compareFunction(param1, param2) {
            return param1.localeCompare(param2,"zh");
          }
        );
        resultArray.splice(array.indexOf('中国大陆'),1)
        resultArray.unshift('中国大陆')
        return resultArray
      },
      //商标查询 图形切换
      trademarkGraphical(item) {
        this.trademarkFlag = item
      },
      //选择年份
      selectYear(item,index) {
        this.value3 = item.value
        document.getElementsByClassName('drop-down-contents')[index].style.display = 'none'
        // document.getElementsByClassName('drop-down-contents')[0].style.display = 'none'
        this.getCountryData()
        this.getGoodsServiceForLantern()
        this.getAppAndRegForLantern()
        this.countryStatisicAllForLantern()
      },
      //数字每三位加逗号
      toThousands(num) {
        var result = [], counter = 0;
        num = (num || 0).toString().split('');
        for (var i = num.length - 1; i >= 0; i--) {
          counter++;
          result.unshift(num[i]);
          if (!(counter % 3) && i != 0) {
            result.unshift(',');
          }
        }
        return result.join('');
      },
      //冒泡排序
      sprt(arr,name) {
        if (Array.isArray(arr,name)) {
          for (var i = arr.length - 1; i > 0; i--) {
            for (var j = 0; j < i; j++) {
              if (arr[j][name] < arr[j + 1][name]) {
                [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
              }
            }
          }
          return arr;
        }
      },
      //echarts图形
      graphical(item) {
        this.isGraphical = item
      },
      //选择国家
      ChangeCountry(item,index) {
        this.value = item
        // if (this.value == '欧盟') {
        //   this.isMultiple = true
        //   this.country = ['奥地利', '比利时', '保加利亚', '塞浦路斯', '克罗地亚', '捷克', '丹麦',
        //     '爱沙尼亚', '芬兰', '法国', '德国', '希腊', '匈牙利', '爱尔兰', '意大利',
        //     '拉脱维亚', '立陶宛', '卢森堡', '马耳他', '荷兰', '波兰', '葡萄牙',
        //     '罗马尼亚', '斯洛伐克', '斯洛文尼亚', '西班牙', '瑞典']
        // } else if (this.value == '比荷卢经济联盟') {
        //   this.isMultiple = true
        //   this.country = ['比利时', '荷兰', '卢森堡']
        // } else {
        //   this.isMultiple = false
        //
        // }
        this.country = [this.value]
        document.getElementsByClassName('drop-down-contents')[index].style.display = 'none'
        this.countryCode = this.allCoutry.filter(item => item.city == this.value)[0].code
        this.$nextTick(() => {
          this.$refs.tableLists ? this.$refs.tableLists.doLayout() : '';
        })
        this.getCountryData()
        this.getGoodsServiceForLantern()
        this.getAppAndRegForLantern()
      },
      //地图选择的国家
      changeCountry(data) {
        // this.country = [data.name]
        this.isMultiple = false
        if (!this.allCoutry.filter(item => item.city == data.name)[0]) return;
        this.value = data.name
        this.countryCode = this.allCoutry.filter(item => item.city == this.value)[0].code
        this.$nextTick(() => {
          this.$refs.tableLists ? this.$refs.tableLists.doLayout() : '';
        })
        this.getCountryData()
        this.getGoodsServiceForLantern()
        this.getAppAndRegForLantern()
      },
      //所有国家的申请量和注册量
      countryStatisicAllForLantern() {
        countryStatisicAllForLantern({
          yearBegin: this.value3 - 10,
          yearEnd: this.value3
        }).then(res => {
          let arr = []
          let countryRanking = []
          let a = {}
          this.tableDatas = []
          let allCountry = Array.from(new Set(res.map(item => item.country)))
          this.seletAllyear = Array.from(new Set(res.map(item => item.year + '')))
          for (let i = 0; i < allCountry.length; i++) {
            for (let j = 0; j < res.length; j++) {
              if (allCountry[i] == res[j].country) {
                a[res[j].year + ''] = res[j].appCount + '/' + res[j].regCount
              }
            }
            a.code = allCountry[i]
            arr.push(a)
            a = {}
          }
          this.allCoutry.map(item => {
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].code == item.code) {
                arr[i].city = item.city
              }
            }
          })

          countryRanking = this.pinyinSorting(arr.map(item => item.city))
          countryRanking.map(item => {
            for (let i = 0 ; i < arr.length ; i++){
              if(item == arr[i].city){
                this.tableDatas.push(arr[i])
              }
            }
          })

        })
      },
      //获取有效注册量、商标总量、申请量
      getCountryData() {
        countryStatisicForLantern({
          "country": this.countryCode,
          "appYear": this.value3
        }).then(res => {
          //申请量
          this.appCount = this.toThousands(res.appCount),
            //有效注册量
            this.effictiveCount = this.toThousands(res.effictiveCount)
          //商标总量
          this.totalCount = this.toThousands(res.totalCount)
          //数据更新时间
          this.updateDate = res.updateDate.split('-')
        })
      },
      //商品服务类别
      getGoodsServiceForLantern() {
        goodsServiceForLantern({
          country: this.countryCode,
          appBeginDate: this.value3 + "-01-01",
          appEndDate: this.value3 + "-12-31"
        }).then(res => {
          let statisticList = res.data.statisticList.map(item => {
            for (let i = 0; i < dataType.length; i++) {
              if (dataType[i].type == item.intcls) {
                item.name = dataType[i].name
              }
            }
            return item
          })
          let tmStatusVOList = res.data.tmStatusStatisticsVOList ? res.data.tmStatusStatisticsVOList.map(item => {
            return {
              value: item.num,
              name: item.tmStatus
            }
          }) : []
          //饼图
          let countAll = 0
          let countAlls = 0
          statisticList.filter(item => {
            countAll += item.intclsCount
          })
          this.pieData = statisticList.length ==  0 ? [] : [
            {value: this.sprt(statisticList,'intclsCount')[0].intclsCount, name: this.sprt(statisticList,'intclsCount')[0].intcls + '类：' + this.sprt(statisticList,'intclsCount')[0].name},
            {value: this.sprt(statisticList,'intclsCount')[1].intclsCount, name: this.sprt(statisticList,'intclsCount')[1].intcls + '类：' + this.sprt(statisticList,'intclsCount')[1].name},
            {value: this.sprt(statisticList,'intclsCount')[2].intclsCount, name: this.sprt(statisticList,'intclsCount')[2].intcls + '类：' + this.sprt(statisticList,'intclsCount')[2].name},
            {
              value: countAll - this.sprt(statisticList,'intclsCount')[0].intclsCount - this.sprt(statisticList,'intclsCount')[1].intclsCount - this.sprt(statisticList,'intclsCount')[2].intclsCount,
              name: '其他'
            }
          ]

          tmStatusVOList.filter(item => {
            countAlls += item.value
          })


          this.pieDatas = tmStatusVOList.length == 0 ? [] : [
            {value: this.sprt(tmStatusVOList,'value')[0].value, name: this.sprt(tmStatusVOList,'value')[0].name},
            {value: this.sprt(tmStatusVOList,'value')[1].value, name: this.sprt(tmStatusVOList,'value')[1].name},
            {value: this.sprt(tmStatusVOList,'value')[2].value, name: this.sprt(tmStatusVOList,'value')[2].name},
            {
              value: countAlls - this.sprt(tmStatusVOList,'value')[0].value - this.sprt(tmStatusVOList,'value')[1].value - this.sprt(tmStatusVOList,'value')[2].value,
              name: '其他'
            }
          ]

        })
      },
      //商标申请量及注册量
      getAppAndRegForLantern() {
        appAndRegForLantern({
          country: this.countryCode,
          yearBegin: this.value3 - 10,
          yearEnd: this.value3,
          "appBeginDate": (this.value3 - 10).toString() + "-01-01",
          "appEndDate": this.value3 + "-12-31",
          "regBeginDate": (this.value3 - 10).toString() + "-01-01",
          "regEndDate": this.value3 + "-12-31",
        }).then(res => {
          let legendYear = res.data.map(item => item.year+ '')
          let appCount = res.data.map(item => item.appCount)
          let regCount = res.data.map(item => item.regCount)
          let applications = res.data.map(item => (item.regCount / (item.appCount + item.regCount) * 100).toFixed(2))
          this.trendsData = {
            legendYear,
            appCount,
            regCount,
            applications
          }
          let arr = []
          this.trendsTableData = []
          let a = {
            name: '申请量'
          }
          let b = {
            name: '注册量'
          }
          let c = {
            name: '已注册占比'
          }
          for (let i = 0; i < res.data.length; i++) {
            a[res.data[i].year] = res.data[i].appCount
            b[res.data[i].year] = res.data[i].regCount
            c[res.data[i].year] = (res.data[i].regCount / (res.data[i].appCount + res.data[i].regCount) * 100).toFixed(2) + '%'
          }
          this.seletAllyears = legendYear
          this.trendsTableData.push(a, b, c)
        })
      },
    }
  }
</script>
<style lang="less" >
  .maptable {
    border-radius: 10px;
    border: 2px solid #00e3fe !important;
    background: #121933!important;
  }

  .maptable .gutter {
    background: #121933
  }

  .maptable .el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
    border-right: 1px solid #203866!important;
  }

  .maptable .el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
    border-right: 1px solid #203866!important;
  }

  .maptable .el-table__body-wrapper::-webkit-scrollbar-track-piece {
    //滚动条凹槽的颜色，还可以设置边框属性
    background-color: #203866;
  }

  .maptable .el-table__body-wrapper::-webkit-scrollbar {
    //滚动条的宽度
    width: 8px;
    height: 8px;
  }

  .maptable .el-table__body-wrapper::-webkit-scrollbar-thumb {
    //滚动条的设置
    background-color: #4993ff;
    /*background-clip: padding-box;*/
    /*min-height: 28px;*/
    border-radius: 8px 8px;
  }

  .maptable .el-table__body-wrapper::-webkit-scrollbar-corner {
    //滚动条交互处修改
    background-color: #203866;
  }

  .maptable .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #4993ff;
  }

  .maptable .el-table__fixed{
      height: 388px!important;
  }

  .maptable .el-table__fixed-body-wrapper{
    height: 340px!important;
  }

  .maptable, .el-table__expanded-cell {
    background: transparent
  }

  .maptable th, .el-table tr {
    background-color: transparent;
  }

  .maptable, .el-table--group {
    border: none;
  }

  .maptable::after, .el-table--group::after, .el-table::before {
    content: '';
    position: absolute;
    background-color: transparent;
    z-index: 1;
  }

  .maptable td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
    border-right: 1px solid #203866!important;
  }


  .maptable th.gutter:last-of-type {
    border-bottom: 1px solid #203866;
    border-bottom-width: 0px;
  }

  .maptable .el-table__fixed-right::before, .el-table__fixed::before {
    background-color: transparent;
  }


</style>
<style lang="less" scoped>
  .brand {
    padding: 0 80px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: url("@{imgUrl}trademarkData/background.png") 50%;

    .top_title {
      width: 1205px;
      position: absolute;
      top: 45px;
      left: 50%;
      margin-left: -602.5px;

      img {
        height: 154px;
        width: auto;
      }

      .top_title_content {
        display: flex;
        align-items: center;
        line-height: 0;
        position: absolute;
        top: 75px;
        z-index: 0;
        font-size: 28px;
        color: #00cdff;
        left: 50%;
        margin-left: -123.5px;

        img {
          margin: 0 8px;
          width: 7px;
          height: 7px;
        }
      }
    }

    .updateDate{
      max-width: 1632px;
      margin: 0 auto;
      color: white;
      text-align: right;
      margin-bottom: 20px;
    }

    .brand_content {
      margin: 0 auto;
      max-width: 1632px;

      .brand_content_total {
        li {
          margin-right: 20%;

          p:nth-of-type(1) {
            font-size: 18px;
            color: #ffffff;
            width: 120px;
          }

          p:nth-of-type(2) {
            margin-top: 17px;
            font-size: 34px;
            color: #4fdbfe;
            min-width: 150px;
          }

          &:nth-last-of-type(1) {
            margin-right: 0px;
          }
        }
      }

      .switch {
        position: relative;
        margin-top: 19px;
        ul {
          display: flex;

          li {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            background: #1b1b1b;

            &.active {
              background: #1f3d86;
            }
          }
        }
      }

      .switchs{
        top: -65px;
        position: relative;
        ul {
          display: flex;

          li {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            background: #1b1b1b;

            &.active {
              background: #1f3d86;
            }
          }
        }
      }

      .trademarkTrendsTitle{
        font-weight: normal;
        font-size: 23px;
        color: #ffffff;
        top: -76px;
        position: relative;
        border-radius: 10px;
        .sub_title{

        }
      }

      .patentDatas{
        margin-bottom: 18px;
        margin-left: 50px;
        color: white;
        font-size: 16px;
      }

      .trademarkTrends {
        .trademarkTrends {
          top: -22px;
          position: relative;
          border-radius: 10px;
          .trademarkTr{
            -moz-box-shadow: inset 0px 0px 34px 2px #00aeff;
            -webkit-box-shadow: inset 0px 0px 34px 2px #00aeff;
            box-shadow: inset 0px 0px 34px 2px #00aeff;
          }

          .patentData{
            position: absolute;
            top: 30px;
            left: 50px;
            color: white;
            font-size: 16px;
          }



          img:nth-of-type(1) {
            position: absolute;
            top: 0;
            left: 0;
          }

          img:nth-of-type(2) {
            position: absolute;
            top: 0;
            right: 0;
          }

          img:nth-of-type(3) {
            position: absolute;
            bottom: 0;
            left: 0;
          }

          img:nth-of-type(4) {
            position: absolute;
            bottom: 0;
            right: -4px;
          }

          span:nth-of-type(1) {
            position: absolute;
            display: block;
            width: 100%;
            height: 1px;
            background: #1182ba;
            top: 1px;
          }

          span:nth-of-type(2) {
            position: absolute;
            display: block;
            height: 100%;
            width: 1px;
            background: #1182ba;
            right: 5px;
          }

          span:nth-of-type(3) {
            position: absolute;
            display: block;
            width: 90%;
            height: 1px;
            background: #1182ba;
            bottom: 1px;
          }

          span:nth-of-type(4) {
            position: absolute;
            display: block;
            height: 100%;
            width: 1px;
            background: #1182ba;
            left: 5px;
          }
        }
      }
    }

    .MapCom{
      width: 60%;
      height: 400px;
      margin-top: 22px;
      margin-left: 25px
    }

    .seletyear {
      .nav {
        display: flex;

        ul li  {
          display: block;
          width: 190px;
          height: 44px;
          text-align: center;
          line-height: 44px;
          color: #47e8fc;
          background-color: #0d3044;
          font-size: 16px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          font-family: -apple-system,BlinkMacSystemFont,'Helvetica Neue',Helvetica,Segoe UI,Arial,Roboto,'PingFang SC',miui,'Hiragino Sans GB','Microsoft Yahei',sans-serif;
        }

        .drop-down {
          width: 190px;
          height: 62px;
          position: relative;
          .drop-down_content {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            background: #12344b;
            /*opacity: 0.5;*/

            img:nth-of-type(1){
              position: absolute;
              top: 0;
              left: 0;
            }

            img:nth-of-type(2){
              position: absolute;
              top: 0;
              right: 0;
            }

            img:nth-of-type(3){
              position: absolute;
              bottom: 0;
              left: 0;
            }

            img:nth-of-type(4){
              position: absolute;
              bottom: 0;
              right: 0;
            }

            .drop-down_content_title {

              font-size: 21px;
              color: #3fe5fd;

            }
          }
          &:hover{
            .sanjiao{
              -webkit-transform:rotate(180deg);
              -moz-transform:rotate(180deg);

            }
          }
        }


        .drop-down:hover .drop-down-contents {
          display: block;
        }

        .drop-down-contents {
          &::-webkit-scrollbar-track-piece {
            //滚动条凹槽的颜色，还可以设置边框属性
            background-color: #0d3044;
          }

          height: 500px;
          overflow: hidden;
          overflow-y: auto;
          display: none;
          border: 1px solid #00f8fc;

          li:hover{
            background-color: #042131;
          }

          &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 4px;
          }

          &:hover::-webkit-scrollbar-thumb {
            background: hsla(0, 0%, 53%, 0.4);
          }

          &:hover::-webkit-scrollbar-track {
            background: hsla(0, 0%, 53%, 0.1);
          }
        }
      }

    }

    .echartsPie {
      position: relative;
      top: 22px;
      -moz-box-shadow: inset 0px 0px 34px 2px #00aeff;
      -webkit-box-shadow: inset 0px 0px 34px 2px #00aeff;
      box-shadow: inset 0px 0px 34px 2px #00aeff;
      background: url("@{imgUrl}trademarkData/law_background.png") no-repeat center;

      &:nth-child(1) {
        float: left;
      }

      &:nth-child(2) {
        float: right;
      }

      width: 459px;
      height: 351px;

      img:nth-of-type(1) {
        position: absolute;
        top: 0;
        left: 0;
      }

      img:nth-of-type(2) {
        position: absolute;
        top: 0;
        right: 0;
      }

      img:nth-of-type(3) {
        position: absolute;
        bottom: 0;
        left: 0;
      }

      img:nth-of-type(4) {
        position: absolute;
        bottom: 0;
        right: -4px;
      }

      span:nth-of-type(1) {
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        background: #1182ba;
        top: 1px;
      }

      span:nth-of-type(2) {
        position: absolute;
        display: block;
        height: 100%;
        width: 1px;
        background: #1182ba;
        right: 5px;
      }

      span:nth-of-type(3) {
        position: absolute;
        display: block;
        width: 90%;
        height: 1px;
        background: #1182ba;
        bottom: 1px;
      }

      span:nth-of-type(4) {
        position: absolute;
        display: block;
        height: 100%;
        width: 1px;
        background: #1182ba;
        left: 5px;
      }

      .chartsPie1 {
        div:nth-of-type(1) {
          position: absolute;
          top: 10px;
          left: -15px;
          display: inline-block;
          vertical-align: middle;
          padding: 10px 53px;
          width: 50%;
          height: 40px;
          font-size: 14px;
          color: #03cdff;
          box-sizing: border-box;
          background: url("@{imgUrl}trademarkData/echarts_back.png") no-repeat center
            center/auto 100%;
        }
        .available{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          flex-direction: column;
          img{
            position: static;
          }
          span{
            position: static;
            display: inline;
            width: 100%;
            height: 0px;
            background: transparent;
            top: 0px;
            text-align: center;
            font-size: 11px;
            margin-top: 20px;
          }
        }
      }

      .chartsPie2 {
        div:nth-of-type(1) {
          position: absolute;
          top: 10px;
          left: 233px;
          display: inline-block;
          vertical-align: middle;
          padding: 10px 29px;
          width: 50%;
          height: 40px;
          font-size: 14px;
          box-sizing: border-box;
          color: #03cdff;
          background: url("@{imgUrl}trademarkData/echarts_back.png") no-repeat;
        }
        .available{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          flex-direction: column;
          img{
            position: static;
          }
          span{
            position: static;
            display: inline;
            width: 100%;
            height: 0px;
            background: transparent;
            top: 0px;
            text-align: center;
            font-size: 11px;
            margin-top: 20px;
          }
        }
      }
    }

    @media screen and (max-width: 1600px) {
      .MapCom{
        width: 55%;
      }
      .brand_content_total{
        li{
          width: 15%;
        }
      }
    }

    @media screen and (max-width: 1300px) {
      .MapCom{
        width: 50%;
      }
      .brand_content_total{
        li{
          width: 15%;
        }
      }
    }

  }
</style>
