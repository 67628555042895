<template>
  <div class="bar">
    <div :id="id" ref="myEchart" :style="{width: width,height:height}"></div>
  </div>

</template>

<script>
  export default {
    name: "bar",
    data(){
      return {
        chart:undefined
      }
    },
    mounted() {
      let _this=this;
      this.echarts_init()
      setTimeout( () => {
        window.addEventListener("resize", () => {
          this.chart.resize();
        })
      },200)
    },
    props:{
      width: {
        type:String,
        default: '100%'
      },
      height: {
        type:String,
        default: '100%'
      },
      id:{
        type:String,
        default: 'pies'
      },
      trendsData:{
        type:Object,
        default: {}
      }
    },
    beforeDestroy() {
      if(this.chart) this.chart.dispose()
      window.removeEventListener("resize", () => {
        this.chart.resize();
      })
    },
    watch:{
      trendsData:{
        handler(){
          this.echarts_init()
        },
        deep:true
      }
    },
    methods:{
      echarts_init(){
        if(this.chart) this.chart.dispose()
        // 获取echarts的容器
        this.chart = echarts.init(document.getElementById(this.id));

        var option = {
          color: ['#4a92ff', '#3a36ef', '#0a85c8'],
          grid:{
            top: '20%',
            bottom: '10%'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            data: ['注册', '申请', '已注册占比'],
            textStyle:{
              color: '#ffffff'
            },
            top: '7%',
            right: '3%'
          },
          xAxis: [
            {
              type: 'category',
              data: this.trendsData.legendYear,
              axisPointer: {
                type: 'shadow'
              },
              nameTextStyle:{
                color: '#ffffff'
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: '#ffffff'   //这里用参数代替了
                }
              },
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '',
              min: 0,
              show: true,
              splitNumber: 5,
              nameTextStyle:{
                color: '#ffffff'
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: '#ffffff'   //这里用参数代替了
                },
                formatter: '{value} '
              },
            },
            {
              type: 'value',
              name: '',
              splitNumber: 5,
              textStyle:{
                color: '#ffffff'
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: '#ffffff'   //这里用参数代替了
                },
                formatter: '{value} %'
              },
            }
          ],
          series: [
            {
              name: '注册',
              type: 'bar',
              stack: 'total',
              barWidth:"30%",
              label: {
                color: '#ffffff',
                show: true
              },
              emphasis: {
                focus: 'series'
              },
              data: this.trendsData.regCount,
            },
            {
              name: '申请',
              type: 'bar',
              stack: 'total',
              label: {
                color: '#ffffff',
                show: true
              },
              emphasis: {
                focus: 'series'
              },
              data: this.trendsData.appCount,
            },
            {
              name: '已注册占比',
              type: 'line',
              yAxisIndex: 1,
              symbolSize: 15,
              data: this.trendsData.applications,
              itemStyle: {
                normal: {
                  lineStyle: {
                    color: "#27b3fc",
                    width:3
                  }
                }
              }
            }
          ]
        };
        this.chart.setOption(option);
      }
    }
  }
</script>

<style scoped>

</style>
