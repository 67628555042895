<template>
  <div class="pie">
    <div :id="id" ref="myEchart" style="width: 100%;height: 100%"></div>
  </div>

</template>

<script>
  export default {
    name: "pie",
    data(){
      return {
        chart:undefined
      }
    },
    mounted() {
      let _this=this;
      this.echarts_init()
      setTimeout( () => {
        window.addEventListener("resize", () => {
          this.chart.resize();
        })
      },200)
    },
    props:{
      width: {
        type:String,
        default: '100%'
      },
      height: {
        type:String,
        default: '100%'
      },
      id:{
        type:String,
        default: 'pie'
      },
      dataT:{
        type: Array,
        default: []
      },
      splice:{
        type:Number,
        default: 2
      }
    },
    beforeDestroy() {
      if(this.chart) this.chart.dispose()
      window.removeEventListener("resize", () => {
        this.chart.resize();
      })
    },
    watch:{
      dataT(){
        this.echarts_init()
      }
    },
    computed:{
    },
    methods:{
      echarts_init(){
        // 获取echarts的容器
        if(this.chart) this.chart.dispose()
        this.chart = echarts.init(document.getElementById(this.id));
        let xdata = this.dataT.map(item => item.name)
        var option = {
          color: ['#fac859', '#91cb76', '#5570c7', '#74c1df', '#fd8b4f'],
          tooltip: {
            trigger: 'item',
            formatter: '{b}:{c}({d}%)'
          },
          grid:{
            left:0,
            top:0,
            right:0,
            bottom:0
          },
          legend: {
            left: 'center',
            top: '68%',
            itemGap:10,
            data: xdata,
            textStyle:{
              color: '#ffffff'
            },
            formatter: function(name) {
              var data = option.series[0].data;
              var total = 0;
              var tarValue;
              for (var i = 0, l = data.length; i < l; i++) {
                total += data[i].value;
                if (data[i].name == name) {
                  tarValue = data[i].value;
                }
              }
              var p = ((tarValue / total) * 100).toFixed(2);
              return name + " " + " " + p + "%";
            }
          },
          series: [
            {
              center:['50%','40%'],
              // name: '访问来源',
              type: 'pie',
              radius: ['40%', '60%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 3,
                borderColor: '#fff',
                borderWidth: 1
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '15'
                }
              },
              labelLine: {
                show: false
              },
              data: this.dataT
            }
          ]
        };
        this.chart.setOption(option);
      }
    }
  }
</script>

<style scoped>

</style>
