<template>
  <div class="mapx">
    <div :id="id" ref="myEchart" :style="{width: width,height:height}"></div>
  </div>

</template>

<script>
  export default {
    name: "mapx",
    data(){
      return {
        chart:undefined,
        BJData: [
          [{
            name: "中国大陆",
            value: 12000
          }],
          [{
            name: "阿根廷",
            value: 1000
          }],
          [{
            name: "阿拉伯联合酋长国",
            value: 1000
          }],
          [{
            name: "爱尔兰",
            value: 1000
          }],
          [{
            name: "奥地利",
            value: 1000
          }],
          [{
            name: "澳大利亚",
            value: 1000
          }],
          [{
            name: "巴西",
            value: 1000
          }],
          [{
            name: "冰岛",
            value: 1000
          }],
          [{
            name: "丹麦",
            value: 1000
          }],
          [{
            name: "德国",
            value: 1000
          }],
          [{
            name: "俄罗斯",
            value: 1000
          }],
          [{
            name: "法国",
            value: 1000
          }],
          [{
            name: "菲律宾",
            value: 1000
          }],
          [{
            name: "芬兰",
            value: 1000
          }],
          [{
            name: "韩国",
            value: 1000
          }],
          [{
            name: "加拿大",
            value: 1000
          }],
          [{
            name: "马来西亚",
            value: 1000
          }],
          [{
            name: "美国",
            value: 1000
          }],
          [{
            name: "墨西哥",
            value: 1000
          }],
          [{
            name: "南非",
            value: 1000
          }],
          [{
            name: "挪威",
            value: 1000
          }],
          [{
            name: "葡萄牙",
            value: 1000
          }],
          [{
            name: "日本",
            value: 1000
          }],
          [{
            name: "瑞典",
            value: 1000
          }],
          [{
            name: "瑞士",
            value: 1000
          }],
          [{
            name: "泰国",
            value: 1000
          }],
          [{
            name: "土耳其",
            value: 1000
          }],
          [{
            name: "西班牙",
            value: 1000
          }],
          [{
            name: "希腊",
            value: 1000
          }],
          [{
            name: "新加坡",
            value: 1000
          }],
          [{
            name: "新西兰",
            value: 1000
          }],
          [{
            name: "匈牙利",
            value: 1000
          }],
          [{
            name: "意大利",
            value: 1000
          }],
          [{
            name: "印度",
            value: 1000
          }],
          [{
            name: "印度尼西亚",
            value: 1000
          }],
          [{
            name: "英国",
            value: 1000
          }],
          [{
            name: "智利",
            value: 1000
          }],
          [{
            name: "中国澳门",
            value: 1000
          }],
          [{
            name: "中国台湾",
            value: 1000
          }],
          [{
            name: "中国香港",
            value: 1000
          }],
          [{
            name: "欧盟",
            value: 1000
          }],
          [{
            name: "比荷卢经济联盟",
            value: 1000
          }],
          [{
            name: "世界知识产权组织国际局",
            value: 1000
          }]
        ],
        selectCountry:[],
        _zoom:1.1,
        dx:'42%',
        dy:'50%',
        center:[0,0]
      }
    },
    beforeDestroy() {
      if(this.chart) this.chart.dispose()
      window.removeEventListener("resize", () => {
        this.chart.resize();
      })
    },
    mounted() {
      if(this.chart) this.chart.dispose()
      this.echarts_init()
      setTimeout( () => {
        window.addEventListener("resize", () => {
          this.chart.resize();
        })
      },200)
    },
    props:{
      width: {
        type:String,
        default: '100%'
      },
      height: {
        type:String,
        default: '100%'
      },
      selectedCountry: {
        type:Array,
        default: ['中国大陆']
      },
      isMultiple:{
        type:Boolean,
        default: false
      },
      id:{
        type:String,
        default: 'main'
      }
    },

    watch:{
      selectedCountry:{
        handler(newValue, oldValue) {
          this.allSelectCountry(newValue[0])
          this.echarts_init()
        },
        deep:true
      }
    },
    methods:{
      //返回选中国家
      allSelectCountry(name){
        this.BJData = this.BJData.map(item => {
          if(item[0].name == name){
            item[0].value = 12000
          }else {
            item[0].value = 1000
          }
          return item;
        })
      },
      echarts_init(){
        if(this.chart) this.chart.dispose()
        // if(this.chart) this.chart.dispose()
        // 获取echarts的容器
        this.chart = echarts.init(document.getElementById(this.id));
        // 国家名英文中文对比
        let  nameComparison = {
          'Canada':'加拿大',
          'Russia':'俄罗斯',
          'ChineseMainland':'中国大陆',
          'United States':'美国',
          'Singapore Rep.':'新加坡',
          'Dominican Rep.':'多米尼加',
          'Palestine':'巴勒斯坦',
          'Bahamas':'巴哈马',
          'Timor-Leste':'东帝汶',
          'Afghanistan':'阿富汗',
          'Guinea-Bissau':'几内亚比绍',
          "Côted'Ivoire":'科特迪瓦',
          'Siachen Glacier':'锡亚琴冰川',
          "Br. Indian Ocean Ter.":'英属印度洋领土',
          'Angola':'安哥拉',
          'Albania':'阿尔巴尼亚',
          'United Arab Emirates':'阿联酋',
          'Argentina':'阿根廷',
          'Armenia':'亚美尼亚',
          'French Southern and Antarctic Lands':'法属南半球和南极领地',
          'Australia':'澳大利亚',
          'Austria':'奥地利',
          'Azerbaijan':'阿塞拜疆',
          'Burundi':'布隆迪',
          'Belgium':'比利时',
          'Benin':'贝宁',
          'Burkina Faso':'布基纳法索',
          'Bangladesh':'孟加拉国',
          'Bulgaria':'保加利亚',
          'The Bahamas':'巴哈马',
          'Bosnia and Herz.':'波斯尼亚和黑塞哥维那',
          'Belarus':'白俄罗斯',
          'Belize':'伯利兹',
          'Bermuda':'百慕大',
          'Bolivia':'玻利维亚',
          'Brazil':'巴西',
          'Brunei':'文莱',
          'Bhutan':'不丹',
          'Botswana':'博茨瓦纳',
          'Central African Rep.':'中非',
          'Switzerland':'瑞士',
          'Chile':'智利',
          'Ivory Coast':'象牙海岸',
          'Cameroon':'喀麦隆',
          'Dem. Rep. Congo':'刚果民主共和国',
          'Congo':'刚果',
          'Colombia':'哥伦比亚',
          'Costa Rica':'哥斯达黎加',
          'Cuba':'古巴',
          'N. Cyprus':'北塞浦路斯',
          'Cyprus':'塞浦路斯',
          'Czech Rep.':'捷克',
          'Germany':'德国',
          'Djibouti':'吉布提',
          'Denmark':'丹麦',
          'Algeria':'阿尔及利亚',
          'Ecuador':'厄瓜多尔',
          'Egypt':'埃及',
          'Eritrea':'厄立特里亚',
          'Spain':'西班牙',
          'Estonia':'爱沙尼亚',
          'Ethiopia':'埃塞俄比亚',
          'Finland':'芬兰',
          'Fiji':'斐',
          'Falkland Islands':'福克兰群岛',
          'France':'法国',
          'Gabon':'加蓬',
          'United Kingdom':'英国',
          'Georgia':'格鲁吉亚',
          'Ghana':'加纳',
          'Guinea':'几内亚',
          'Gambia':'冈比亚',
          'Guinea Bissau':'几内亚比绍',
          'Eq. Guinea':'赤道几内亚',
          'Greece':'希腊',
          'Greenland':'格陵兰',
          'Guatemala':'危地马拉',
          'French Guiana':'法属圭亚那',
          'Guyana':'圭亚那',
          'Honduras':'洪都拉斯',
          'Croatia':'克罗地亚',
          'Haiti':'海地',
          'Hungary':'匈牙利',
          'Indonesia':'印度尼西亚',
          'India':'印度',
          'Ireland':'爱尔兰',
          'Iran':'伊朗',
          'Iraq':'伊拉克',
          'Iceland':'冰岛',
          'Israel':'以色列',
          'Italy':'意大利',
          'Jamaica':'牙买加',
          'Jordan':'约旦',
          'Japan':'日本',
          'Kazakhstan':'哈萨克斯坦',
          'Kenya':'肯尼亚',
          'Kyrgyzstan':'吉尔吉斯斯坦',
          'Cambodia':'柬埔寨',
          'Korea':'韩国',
          'Kosovo':'科索沃',
          'Kuwait':'科威特',
          'Lao PDR':'老挝',
          'Lebanon':'黎巴嫩',
          'Liberia':'利比里亚',
          'Libya':'利比亚',
          'Sri Lanka':'斯里兰卡',
          'Lesotho':'莱索托',
          'Lithuania':'立陶宛',
          'Luxembourg':'卢森堡',
          'Latvia':'拉脱维亚',
          'Morocco':'摩洛哥',
          'Moldova':'摩尔多瓦',
          'Madagascar':'马达加斯加',
          'Mexico':'墨西哥',
          'Macedonia':'马其顿',
          'Mali':'马里',
          'Myanmar':'缅甸',
          'Montenegro':'黑山',
          'Mongolia':'蒙古',
          'Mozambique':'莫桑比克',
          'Mauritania':'毛里塔尼亚',
          'Malawi':'马拉维',
          'Malaysia':'马来西亚',
          'Namibia':'纳米比亚',
          'New Caledonia':'新喀里多尼亚',
          'Niger':'尼日尔',
          'Nigeria':'尼日利亚',
          'Nicaragua':'尼加拉瓜',
          'Netherlands':'荷兰',
          'Norway':'挪威',
          'Nepal':'尼泊尔',
          'New Zealand':'新西兰',
          'Oman':'阿曼',
          'Pakistan':'巴基斯坦',
          'Panama':'巴拿马',
          'Peru':'秘鲁',
          'Philippines':'菲律宾',
          'Papua New Guinea':'巴布亚新几内亚',
          'Poland':'波兰',
          'Puerto Rico':'波多黎各',
          'Dem. Rep. Korea':'朝鲜',
          'Portugal':'葡萄牙',
          'Paraguay':'巴拉圭',
          'Qatar':'卡塔尔',
          'Romania':'罗马尼亚',
          'Rwanda':'卢旺达',
          'W. Sahara':'西撒哈拉',
          'Saudi Arabia':'沙特阿拉伯',
          'Sudan':'苏丹',
          'S. Sudan':'南苏丹',
          'Senegal':'塞内加尔',
          'Solomon Is.':'所罗门群岛',
          'Sierra Leone':'塞拉利昂',
          'El Salvador':'萨尔瓦多',
          'Somaliland':'索马里兰',
          'Somalia':'索马里',
          'Serbia':'塞尔维亚',
          'Suriname':'苏里南',
          'Slovakia':'斯洛伐克',
          'Slovenia':'斯洛文尼亚',
          'Sweden':'瑞典',
          'Swaziland':'斯威士兰',
          'Syria':'叙利亚',
          'Chad':'乍得',
          'Togo':'多哥',
          'Thailand':'泰国',
          'Tajikistan':'塔吉克斯坦',
          'Turkmenistan':'土库曼斯坦',
          'East Timor':'东帝汶',
          'Trinidad and Tobago':'特里尼达和多巴哥',
          'Tunisia':'突尼斯',
          'Turkey':'土耳其',
          'Tanzania':'坦桑尼亚',
          'Uganda':'乌干达',
          'Ukraine':'乌克兰',
          'Uruguay':'乌拉圭',
          'Uzbekistan':'乌兹别克斯坦',
          'Venezuela':'委内瑞拉',
          'Vietnam':'越南',
          'Vanuatu':'瓦努阿图',
          'West Bank':'西岸',
          'Yemen':'也门',
          'South Africa':'南非',
          'Zambia':'赞比亚',
          'Zimbabwe':'津巴布韦'
        };
        // 数据
        let dataMap = this.selectedCountry.map(item => {
          return {"name": item,"selected":true}
        })
        // let dataMap = [
        //   {"name": '中国大陆',"selected":true},
        //   {"name": '韩国',"selected":true}
        // ]
        var geoCoordMap = {
          "中国大陆":[95.265056,36.039831],
          "阿根廷":[-68.428342,-36.141176],
          "阿拉伯联合酋长国":[53.93547,23.513818],
          "爱尔兰":[-8.361168,53.438717],
          "奥地利":[15.243884,48.752798],
          "澳大利亚":[130.667204,-24.288906],
          "巴西":[-56.359716,-8.580118],
          "冰岛":[-20.299318,64.711236],
          "丹麦":[8.913894,56.064055],
          "德国":[8.80351,51.003816],
          "俄罗斯":[87.323173,63.631202],
          "法国":[0.63511,46.785766],
          "菲律宾":[121.040818,12.906169],
          "芬兰":[26.860081,68.273805],
          "韩国":[127.483322,36.462671],
          "加拿大":[-65.218389,55.358674],
          "马来西亚":[102.082404,4.231081],
          "美国":[-91.268962,37.947441],
          "墨西哥":[-98.62788,18.405873],
          "南非":[21.204983,-30.641753],
          "挪威":[7.515699,61.08906],
          "欧盟":[-150.434552,-30.014814],
          "比荷卢经济联盟":[-150.434552,-37.014814],
          "世界知识产权组织国际局":[-150.434552,-44.014814],
          "葡萄牙":[-8.89469,39.331374],
          "日本":[140.960116,39.907078],
          "瑞典":[18.470914,65.56206],
          "瑞士":[7.672076,46.787741],
          "泰国":[101.22578,15.050843],
          "土耳其":[42.479386,38.96551],
          "西班牙":[-2.925142,42.38215],
          "希腊":[21.037712,39.004659],
          "新加坡":[103.791053,1.423586],
          "新西兰":[169.448706,-44.694017],
          "匈牙利":[18.316232,45.888912],
          "意大利":[10.974391,43.162035],
          "印度":[76.900729,15.609235],
          "印度尼西亚":[119.664471,-3.13314],
          "英国":[-4.91205,57.462888],
          "智利":[-69.53218,-23.944839],
          "中国澳门":[113.581202,22.12934],
          "中国台湾":[121.486578,24.888535],
          "中国香港":[114.343827,22.462182]
        };
        var series = [];
        [
          [, this.BJData]
        ].forEach(function(item, i) {
          series.push({
            type: "effectScatter",
            coordinateSystem: "geo",
            zlevel: 2,
            rippleEffect: {
              //涟漪特效
              period: 4, //动画时间，值越小速度越快
              brushType: "stroke", //波纹绘制方式 stroke, fill
              scale: 4
              //波纹圆环最大限制，值越大波纹越大
            },
            label: {
              normal: {
                show: true,
                position: "right", //显示位置
                offset: [5, 0], //偏移设置
                formatter: "{b}" //圆环显示文字
              },
              emphasis: {
                show: true
              }
            },
            symbol: "circle",
            symbolSize: 10,
            data: item[1].map(function(dataItem) {
              return {
                name: dataItem[0].name /*+"\n"+dataItem[0].value*/ ,
                value: geoCoordMap[dataItem[0].name]
                  .concat([dataItem[0].value])
              };
            })
          });
        });
        let option = {
          //控制地图位置
          mapLocation: {
            y: 100,
            x: -15
          },
          nameMap: 0,
          //背景色
          background:'#198fd5',
          //是否支持多选
          selectedMode: this.isMultiple ? 'multiple' : 'single',
          //悬浮提示
          tooltip: {
            trigger: "item",
            // backgroundColor: "#1540a1",
            borderColor: "#000",
            showDelay: 0,
            hideDelay: 0,
            // enterable: true,
            transitionDuration: 0,
            formatter: '{b}'
            // extraCssText: "z-index:100",formatter
            // formatter: function(params, ticket, callback) {
            //   //根据业务自己拓展要显示的内容
            //   var res = "";
            //   var name = params.name;
            //   var value = params.value[params.seriesIndex + 1];
            //   res = "<span style='color:#fff;'>" + name.toString().split(' ')[0] +
            //     "</span><br/>爬虫：" + name.toString().split(' ')[1];
            //   return res;
            // }
          },
          visualMap: {
            //图例值控制
            min: 0,
            max: 10000,
            text: ['High', 'Low'],
            show: false,
            calculable: true,
            //color: ["#0bc7f3"],
            color: ['orangered', 'yellow']
          },
          geo: {
            map: "world",
            zoom:this._zoom, //设置初始化缩放比例
            center: this.center,

            label: {
              emphasis: {
                show: false
              }
            },
            roam: true, //是否允许缩放
            layoutCenter: ["50%", "48%"], //地图位置
            layoutSize: "170%",
            itemStyle: {
              normal: {
                label: {
                  show: true,//默认是否显示省份名称

                },
                areaColor: "#0c91d2", //区域颜色
                borderWidth:1,
                borderColor:'#e1e1e1',
              },
              emphasis: {
                borderColor: '#d0b20b',
                borderWidth: 1,
                color:'#0c91d2'
              }
            }
          },
          series: series
        };
        this.chart.setOption(option);

        this.chart.on("georoam", params => {

          // if (params.dy || params.dx) return;
          let _option = this.chart.getOption();     //获取最新的option配制
          this._zoom = _option.geo[0].zoom;           //获取当前缩放比例zoom
          this.center = _option.geo[0].center
          // this.chart.setOption(_option);        //更新修改后的option配制
        });
        this.chart.on('click', (params) => {
          if(params.componentType == "geo") return;
          this.allSelectCountry(params.name)
          this.echarts_init()
          this.$emit('changeCountry',params)
        });//点击事件，此事件还可以用到柱状图等其他地图

      }
    }
  }
</script>

<style scoped>

</style>
