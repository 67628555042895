<template>
  <div class="pie">
    <div :id="id" ref="myEchart" style="width: 100%;height: 100%"></div>
  </div>

</template>

<script>
  export default {
    name: "pie",
    data(){
      return {
        chart:undefined
      }
    },
    mounted() {
      if(this.chart) this.chart.dispose()
      this.echarts_init()
      setTimeout(function (){
        window.onresize = function () {
          this.chart.resize();
        }
      },200)
    },
    props:{
      transverseData:{
        type:Object,
        default: {
          xdata:[],
          data:[]
        }
      },
      width: {
        type:String,
        default: '100%'
      },
      height: {
        type:String,
        default: '100%'
      },
      id:{
        type:String,
        default: 'pie'
      }
    },
    beforeDestroy() {
      if(this.chart) this.chart.dispose()
    },
    watch:{
      transverseData:{
        handler(){
          this.echarts_init()
        },
        deep:true
      }
    },
    methods:{
      echarts_init(){
        // 获取echarts的容器
        this.chart = echarts.init(document.getElementById(this.id));
        var option = {
          title: {
            text: '世界人口总量',
            subtext: '数据来自网络'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ['2011年']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: this.transverseData.datax
          },
          series: [
            {
              name: '2011年',
              type: 'bar',
              data: this.transverseData.data
            }
          ]
        };
        this.chart.setOption(option);
      }
    }
  }
</script>

<style scoped>

</style>
